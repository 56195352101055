import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    // Customizable Area Start
    MenuItem,
    Select
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik } from "formik";

import Loader from "../../../components/src/Loader";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ChangeEvent } from "react";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 600,
        },
        subtitle1: {
            margin: "25px 0px",
        },
    },
});

interface formType {
    errors: {
        first_name: string;
        last_name: string;
        email: string;
        location: string;
        company: string;
        designation: string;
    },
    touched: {
        first_name: string;
        last_name: string;
        email: string;
        location: string;
        company: string;
        designation: string;
    },
    values: {
        firstName: string;
        lastName: string;
        email: string;
        location: string;
        companyName: string;
        designation: string;
    },
    handleSubmit: () => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement> | ChangeEvent<{ name?: string | undefined; value: unknown; }>) => void;
    handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

// Customizable Area End

import DownloadableproductsController, {
    Props,
} from "./DownloadableproductsController";

export default class Downloadableproducts extends DownloadableproductsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Loader loading={this.state.isLoading} />
                <Container maxWidth={"sm"}>
                    <Box sx={webStyle.mainWrapper}>
                        <Typography variant="h6">{this.pdfLabelTitleText}</Typography>
                        <Typography variant="subtitle1" component="div">
                            {this.pdfLabelBodyText}
                        </Typography>
                        <Formik
                            initialValues={{ first_name: "", last_name: "", email: "", company: "", designation: "", location: "" }}
                            validationSchema={this.codeSchema}
                            onSubmit={(values: { first_name: string, last_name: string, email: string, company: string, designation: string, location: string }) => this.handleSubmitForm(values)}
                            data-test-id="download-pdf-formik"
                        >
                            {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: formType) => (
                                <Box sx={webStyle.inputWrapper}>
                                    <Box sx={webStyle.inputStyle}>
                                        <InputLabel id="first-name">
                                            {this.labelFirstName}
                                        </InputLabel>
                                        <Input
                                            data-test-id={"txtInputFirstName"}
                                            type="text"
                                            placeholder={this.txtInputPlaceholder + this.labelFirstName}
                                            fullWidth={true}
                                            disableUnderline={true}
                                            value={values.firstName}
                                            name="first_name"

                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Box sx={webStyle.fieldError} data-test-id="errorMessage">
                                            {errors.first_name} {touched.first_name}
                                        </Box>

                                    </Box>

                                    <Box sx={webStyle.inputStyle}>
                                        <InputLabel id="last-name">
                                            {this.labelLastName}
                                        </InputLabel>
                                        <Input
                                            data-test-id={"txtInputLastName"}
                                            type="text"
                                            placeholder={this.txtInputPlaceholder + this.labelLastName}
                                            fullWidth={true}
                                            disableUnderline={true}
                                            value={values.lastName}
                                            name="last_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Box sx={webStyle.fieldError} data-test-id="errorMessage">
                                            {errors.last_name} {touched.last_name}
                                        </Box>
                                    </Box>
                                    <Box sx={webStyle.inputStyle}>
                                        <InputLabel id="email">
                                            {this.labelEmail}
                                        </InputLabel>
                                        <Input
                                            data-test-id={"txtInputEmail"}
                                            type="text"
                                            placeholder={this.txtInputPlaceholder + this.labelEmail}
                                            fullWidth={true}
                                            disableUnderline={true}
                                            value={values.email}
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Box sx={webStyle.fieldError} data-test-id="errorMessage">
                                            {errors.email} {touched.email}
                                        </Box>
                                    </Box>
                                    <Box sx={webStyle.inputStyle}>
                                        <InputLabel id="company-name">
                                            {this.labelCompanyName}
                                        </InputLabel>
                                        <Select
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            labelId="demo-simple-select-label"
                                            id="company-name-select"
                                            data-test-id={"company-name-select"}
                                            name="company"
                                            value={values.companyName}
                                            onChange={handleChange}
                                            defaultValue=""
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value="">{this.selectPlaceholder + this.labelCompanyName}</MenuItem>
                                            <MenuItem value="company-a">Company A</MenuItem>
                                            <MenuItem value="company-b">Company B</MenuItem>
                                            <MenuItem value="company-c">Company C</MenuItem>
                                        </Select>
                                        <Box sx={webStyle.fieldError}>
                                            {errors.company} {touched.company}
                                        </Box>
                                    </Box>
                                    <Box sx={webStyle.inputStyle}>
                                        <InputLabel id="location-name">
                                            {this.labelLocation}
                                        </InputLabel>
                                        <Input
                                            data-test-id={"txtInputLocation"}
                                            type="text"
                                            placeholder={this.txtInputPlaceholder + this.labelLocation}
                                            fullWidth={true}
                                            disableUnderline={true}
                                            value={values.location}
                                            onChange={handleChange}
                                            name="location"
                                            onBlur={handleBlur}
                                        />
                                        <Box sx={webStyle.fieldError}>
                                            {errors.location} {touched.location}
                                        </Box>
                                    </Box>
                                    <Box sx={webStyle.inputStyle}>
                                        <InputLabel id="designation-name">
                                            {this.labelDesignation}
                                        </InputLabel>
                                        <Input
                                            data-test-id={"txtInputDesignation"}
                                            type="text"
                                            placeholder={this.txtInputPlaceholder + this.labelDesignation}
                                            fullWidth={true}
                                            disableUnderline={true}
                                            value={values.designation}
                                            onChange={handleChange}
                                            name="designation"
                                            onBlur={handleBlur}
                                        />
                                        <Box sx={webStyle.fieldError}>
                                            {errors.designation} {touched.designation}
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button data-test-id={"btnSubmit"} onClick={() => handleSubmit()} variant="contained">{this.btnSubmitTitle}</Button>
                                    </Box>
                                </Box>
                            )}
                        </Formik>
                        <Box>
                            <Button disabled={!this.state.formSubmited} data-test-id={"download-pdf-btn"} onClick={() => this.downloadPdfPreview()} variant="contained">{this.btnDownloadPdfTitle}</Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
        gap: "20px"
    },
    inputWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        width: "100%"
    },
    inputStyle: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    fieldError: {
        borderTop: "1px solid rgba(0, 0, 0, 0.6)",
        color: "#D11024",
        fontSize: "14px",
        paddingTop: "5px"
    },
    buttonStyle: {
        width: "100%",
        height: "50px",
        marginTop: "50px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
};
// Customizable Area End
